import React from 'react';
import '../../styles/font-awesome.min.css';
import '../../styles/bootstrap.min.css';
import './Layout.css';

import Header from '../Header';
import Footer from '../Footer';


export default function Layout({ children }) {
  return (
    <div>
      <Header />
        {children}
      <Footer />
    </div>
  );
}
