import React from "react"
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import {
	Container,
	Row,
	Col
} from 'reactstrap';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Container className='py-5 text-center'>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Row>
      <Col className="col-sm-5 mx-auto">
        <img src="../../clown.svg" alt=""/>
      </Col>
    </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
